import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import WindoWidth from './WindowWidth';
const WP_URL = process.env.GATSBY_WP_API;

function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, '');
}

const WhatsNew = (props) => {
  const [wpData, setWordpressData] = useState(null);
  const [wpLoading, setWordpressLoading] = useState(false);

  const fetchFeatureData = async () => {
    setWordpressLoading(true);
    return fetch(`${WP_URL}/wp-json/acf/v3/new_features`)
      .then((data) => data.json())
      .then((data) => {
        setWordpressData(data);
        setWordpressLoading(false);
        props.setNewFeats(data ? data.length : null);
        return data;
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (!wpData) {
      fetchFeatureData();
    }
  }, []);

  const windowWidth = WindoWidth();

  return (
    <Modal
      title={`What's new?`}
      show={props.openFeatureModal}
      handleClose={() => props.setOpenNewFeature(false)}
      headerStyle={{
        backgroundColor: '#bf9191',
        color: 'white',
      }}
      size={windowWidth < 670 ? 'sm' : 'md'}
      dialogClassName={'new-feature-modal-dialog'}
    >
      <div className='modal-new-ft-main'>
        {wpData && wpData.length > 0 && (
          <div className='updates'>
            <span style={{ fontSize: '18px' }}>‣</span> There are{' '}
            {wpData && wpData.length} new updates you should check out!
          </div>
        )}
        {wpLoading ? (
          <div
            style={{ margin: 'auto', fontSize: '18px', textAlign: 'center' }}
          >
            Content loading <i className='fas fa-circle-notch fa-spin' />
          </div>
        ) : (
          <>
            {wpData && wpData.length > 0 ? (
              <>
                {wpData.map((wp, idx) => {
                  return (
                    <div className='modal-new-feature' key={idx}>
                      <>
                        <div className='new-feat-title'>
                          <span
                            style={{ fontSize: '21px', marginRight: '10px' }}
                          >
                            🎉
                          </span>
                          {wp.acf && wp.acf.feature_title}
                        </div>
                        <div className='new-feat-image'>
                          <img src={wp.acf && wp.acf.feature_image} alt='new feat' />
                        </div>
                        <div className='new-feat-desc'>
                          {wp.acf && wp.acf.feature_description
                            ? removeTags(wp.acf.feature_description)
                            : ''}
                        </div>
                      </>
                    </div>
                  );
                })}
              </>
            ) : (
              <div> No updates!</div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default WhatsNew;
